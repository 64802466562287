<template>
    <!--  修改信息-->
    <div class="changeInformation">
        <div style="width: 60%;margin: auto">


        <el-row style="margin:20px">

            <el-form :inline="true" ref="form" label-width="120px">
                <el-row :span="24" style="margin:10px auto">个人信息</el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="name" class="form-item">
                            <el-input size="mini" v-model="name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="派送地址:" prop="addr" class="form-item">
                            <el-input size="mini" v-model="addr"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-col :span="24" style="margin:10px auto">银行卡信息</el-col>
                <el-form-item label="放款方式:" prop="loanWay" style="float: left;width: 50%" class="form-item">
                    <el-select v-model="loanWay" size="mini" placeholder="" disabled>
                        <el-option label="自动放款" :value="1"></el-option>
                        <el-option label="自助放款" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="身份证号:" prop="idNumb" style="float: right;width: 50%" class="form-item">
                    <el-input size="mini" v-model="idNumb"></el-input>
                </el-form-item>
                <el-form-item label="开户人:" prop="accountHolder" style="float: left;width: 50%" class="form-item">
                    <el-input size="mini" v-model="accountHolder"></el-input>
                </el-form-item>
                <el-form-item label="银行卡号:" prop="bankNumb" style="float: right;width: 50%" class="form-item">
                    <el-input size="mini" v-model="bankNumb"></el-input>
                </el-form-item>
                <el-form-item label="开户行:" prop="bankInfoId" style="float: left;" class="form-item">
                    <el-select v-model="bankInfoId" size="mini" placeholder="">
                        <el-option
                                v-for="item in bankList"
                                :key="item.id"
                                :label="item.bankName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="验证码:" prop="checkCode" style="float: right;width: 50%" class="form-item">
                    <el-input
                            v-model="checkCode"
                            class="code"
                            placeholder="请输入验证码"
                            clearable
                            size="mini"
                    >
                        <template #suffix>
                            <el-button @click="SMSIn" style="border: none" size="mini">
                                <span v-show="SMSdisabled">{{SMSbutton}}</span>
                                <span v-show="!SMSdisabled" class="code">{{checkCode}}</span>
                            </el-button>
                        </template>
                    </el-input>
                    <div style="color:  #B2A9A9">短信将发送到{{phone}}</div>

                </el-form-item>

                <el-col :span="24" style="font-size: 14px;color: #707e99;margin-top: 30px;">帮助<i
                        class="el-icon-warning-outline"></i></el-col>
                <el-col :span="24" style="font-size: 14px;color: #707e99;margin-top: 10px">
                    除工商银行，客户放款卡使用其他银行放款，需要额外支付转款手续费。(放款手续费+额外跨行转款手续费)
                    如需变更放款方式，请联系原注册网点或400客服
                </el-col>
            </el-form>

        </el-row>
        <div style="margin: 100px auto;text-align: center">
            <el-button type="primary" size="mini" @click="submit()">保存</el-button>
            <!--      <span style="font-size: 14px;color: #fe5100;">上次保存时间:{{lastTime}}</span>-->
        </div>
        <div>

        </div>
        </div>
    </div>
</template>

<script>
    import {
        findBankList,
        updateUserName,
        findCustomerById,
        sendMobileCode,
        hiddenPhoneNumber,
        bindBankNo
    } from "../api/changeInformation";

    var interval = null; //倒计时函数
    export default {
        components: {},

        data() {
            return {
                lastTime: '',
                input: '',
                addr: '',//派送地址
                userId: '',
                name: '',//姓名
                bankList: [],
                bankId: '',
                bankIndex: '',
                SMSN: '',
                SMSdisabled: true,
                SMSbutton: '获取验证码',
                loanWay: '',//放款方式
                currentTime: 61, //倒计时
                accountHolder: '',//开户人
                bankInfoId: '',//开户行id
                bankNumb: '',//银行卡号
                checkCode: '',//验证码
                idNumb: '',//身份证号
                phone: '',//手机号
            };
        },
        methods: {
            //保存
            submit() {
                if (!this.name) {
                    this.$message({type: 'error', message: '请输入姓名!', offset: 80,});
                    return false;
                }
                if (!this.addr) {
                    this.$message({type: 'error', message: '请输入地址!', offset: 80,});
                    return false;
                }
                if (this.name.replace(/\s/g, '') == '长吉会员') {
                    this.$message({type: 'error', message: '不可以输入\'长吉会员\'关键字!', offset: 80,});
                    return false;
                }
                if (!this.idNumb) {
                    this.$message({type: 'error', message: '请输入身份证号!', offset: 80,});
                    return false;
                }
                if (!this.accountHolder) {
                    this.$message({type: 'error', message: '请输入开户人!', offset: 80,});
                    return false;
                }
                if (!this.bankNumb) {
                    this.$message({type: 'error', message: '请输入银行卡号!', offset: 80,});
                    return false;
                }
                if (!this.validateCardNo(this.bankNumb)) {
                    this.$message({type: 'error', message: '请输入正确银行卡号!', offset: 80,});
                    return false;
                }
                if (!this.checkCode) {
                    this.$message({type: 'error', message: '请输入验证码!', offset: 80,});
                    return false;
                } else {
                    this.changeUserName();
                    this.boundBankNo();
                }
            },
            //获取绑定银行卡手机号发送验证码短信
            getPhone() {
                hiddenPhoneNumber({}).then((res) => {
                    if (res.code === 200) {
                        this.phone = res.data
                    }
                })
            },
            //发验证码
            SMSIn() {
                sendMobileCode({}).then((res) => {
                    if (res.code === 200) {
                        this.getCode();
                        this.SMSdisabled = true
                    } else {
                        this.$message({type: 'error', message: res.msg, offset: 80,});
                        return false;
                    }
                })
            },
            //验证码倒计时
            getCode() {
                var currentTime = this.currentTime
                interval = setInterval(() => {
                    currentTime--;
                    this.SMSbutton = currentTime + '秒'
                    if (currentTime <= 0) {
                        clearInterval(interval)
                        this.SMSbutton = '重新发送';
                        this.currentTime = 61;
                        this.SMSdisabled = true;
                    }
                }, 1000)
            },
            validateCardNo(cardNo) {
                // 银行卡号正则表达式
                var reg = /^([1-9]{1})(\d{15}|\d{18})$/;
                return reg.test(cardNo);
            },
            //银行卡信息提交
            boundBankNo() {
                let params = {
                    accountHolder: this.accountHolder,
                    bankInfoId: this.bankInfoId,
                    bankNumb: this.bankNumb,
                    checkCode: this.checkCode,
                    idNumb: this.idNumb,
                }
                bindBankNo(params).then((res) => {
                    if (res.code === 200) {
                        this.$message({type: 'success', message: '银行卡信息保存成功！', offset: 80,})
                    } else {
                        this.$message({type: 'error', message: res.msg, offset: 80,});
                        return false;
                    }
                })
            },
            //查找用户绑定银行卡信息
            getCustomerById() {
                findCustomerById().then((res) => {
                    if (res.code === 200) {
                        this.accountHolder = res.data.accountHolder;
                        this.bankNumb = res.data.bankNumb;
                        this.idNumb = res.data.idNumb;
                        this.bankInfoId = res.data.bankInfoId;
                        this.loanWay = res.data.loanWay;
                        this.bankList.forEach((val, index) => {
                            if (val.id == this.bankInfoId) {
                                this.bankIndex = index;
                            }
                        })
                    }
                })
            },
            //修改个人信息保存
            changeUserName() {
                let params = {
                    userId: JSON.parse(localStorage.getItem("userObj")).userId,
                    custName: this.name,
                    addr: this.addr
                }
                updateUserName(params).then((res) => {
                    if (res.code === 200) {
                        // this.$message({type: 'success', message: '个人信息保存成功!', offset: 80,});
                        let userObj = JSON.parse(localStorage.getItem("userObj"));
                        userObj.userName = params.custName;
                        userObj.addr = params.addr;
                        localStorage.setItem('userObj', JSON.stringify(userObj));
                    } else {
                        this.$message({type: 'error', message: res.msg, offset: 80,});
                    }
                })
            },
            //获取开户行列表
            getBankList() {
                findBankList().then((res) => {
                    if (res.code === 200) {
                        this.bankList = res.data;
                        this.getCustomerById();
                    }
                })
            }
        },
        mounted() {
            this.getBankList();
            this.getPhone()
            this.addr = JSON.parse(localStorage.getItem("userObj")).addr;
            this.name = JSON.parse(localStorage.getItem("userObj")).userName;

        }
    };
</script>

<style scoped>
    .form-item {
        margin: 10px auto;
    }

    .el-input__suffix-inner .el-button {
        color: #00aaf8;
    }

    .code /deep/ .el-input__wrapper {
        padding-right: 1px;
    }
    .el-select{width: 200px!important;}
    .el-input{width: 200px!important;}
</style>
