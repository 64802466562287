import request from "../utils/request";
//获取开户行列表
export function findBankList(params) {
    return request({
        url: "bindIdCard/findBankList",
        method: "post",
        params: params,
    });
}
//修改个人信息
export function updateUserName(params) {
    return request({
        url: "wx/save",
        method: "post",
        params: params,
    });
}
//查找用户绑定银行卡信息
export function findCustomerById(params) {
    return request({
        url: "bindIdCard/findCustomerById",
        method: "post",
        params: params,
    });
}
//获取绑定银行卡手机号发送验证码短信
export function hiddenPhoneNumber(params) {
    return request({
        url: "bindIdCard/hiddenPhoneNumber",
        method: "post",
        params: params,
    });
}
//绑定银行卡
export function bindBankNo(params) {
    return request({
        url: "bindIdCard/bindBankNo",
        method: "post",
        params: params,
    });
}
//发验证码
export function sendMobileCode(params) {
    return request({
        url: "bindIdCard/sendMobileCode",
        method: "post",
        params: params,
    });
}